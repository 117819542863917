import React from 'react';
import {makeStyles} from "@material-ui/styles";
import globalStyles from "../helpers/GlobalStyles";
import '../App.css';
import logo from '../svgs/darwin.svg';
import video1 from '../videos/maodou.mp4';

import {Fab} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        background: "#000 !important",
        color: "#fff !important"
    },
    helpBk: {
        padding: "10px",
        background: "#000",
        "& img": {
            maxWidth: "100%"
        }
    },
    helpImg: {
        marginTop: "10px",
    },
    fabDiv: {
        position: 'fixed',
        bottom: 16,
        right: 16,
        maxWidth: "600px"
    },
    fabStyle: {
        fontFamily: 'IRANYekanXVF'
    }
}), {index: 1});

function IOS() {
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <div className="App">
            <a className={globalClasses.logoDiv}>
                <img className={globalClasses.logo} src={logo}/>
            </a>
            <p className={`${globalClasses.title} ${classes.title}`}>
                راهنمای نصب و راه اندازی نرم افزار maodou برای آیفون
            </p>

            <div className={classes.helpBk}>
                <video src={video1} width="400" height="900" controls="controls" autoPlay="true"/>
            </div>

            <div className={classes.fabDiv}>
                <Fab className={classes.fabStyle} aria-label='برگشت' color='primary' href="/">
                    {/*<EditIcon />*/}
                    برگشت
                </Fab>
            </div>
        </div>
    );
}

export default IOS;