import './App.css';
import React from "react";
import {Route, Switch, Redirect, BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@material-ui/styles";
import {createTheme} from "@material-ui/core";
import NotFound from "./pages/NotFound";
import Main from "./pages/Main";
import Ios from "./pages/Ios";
import IosMaodou from "./pages/IosMaodou";
import IosChangeServer from "./pages/IosChangeServer";
import AndroidOpen from "./pages/AndroidOpen";
import WindowsOpen from "./pages/WindowsOpen";
import WindowsAny from "./pages/WindowsAny";

let theme = createTheme({
    palette: {
        primary: {
            main: "#D3D3D3"
        },
        secondary: {
            main: "#ffffff"
        }
    },
    typography: {
        fontFamily: 'Arial',
    },
    overrides: {
        MuiButton: {
            root: {
                width: "100%",
                justifyContent: "start",
                fontFamily: 'IRANYekanXVF',
                padding: "12px 16px !important",
                textTransform: "capitalize"
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: '20px'
            }
        },
        MuiAccordionDetails: {
            root: {
                display: "block"
            }
        },
        MuiAccordion: {
            root: {
                '&:first-child': {
                    borderTopRightRadius: "20px !important",
                    borderTopLeftRadius: "20px !important",
                },
                '&:last-child': {
                    borderBottomRightRadius: "20px !important",
                    borderBottomLeftRadius: "20px !important",
                }
            }
        }
    }
});

function App() {
    return (
        <React.Fragment>
            <BrowserRouter >
                <ThemeProvider theme={theme}>
                    {/*<ReactPWAInstallProvider>*/}
                    {/*  <PWAInstall/>*/}
                    {/*</ReactPWAInstallProvider>*/}
                    <Switch>
                        {/*<Route exact path="/" render={() => <Redirect to="/app/profile" />} />*/}
                        {/*<PublicRoute path="/forgot" component={Forgot} />*/}
                        <Route  path="/" component={Main} exact/>
                        <Route  path="/ios" component={Ios}/>
                        <Route  path="/ios-maodou" component={IosMaodou}/>
                        <Route  path="/ios-change-server" component={IosChangeServer}/>
                        <Route  path="/android-openConnect" component={AndroidOpen}/>
                        <Route  path="/windows-openConnect" component={WindowsOpen}/>
                        <Route  path="/windows-anyconnect" component={WindowsAny}/>
                        <Route component={NotFound} />
                    </Switch>
                </ThemeProvider>
            </BrowserRouter>
        </React.Fragment>
    );
}


const PublicRoute = ({ component, isAuthenticated,  ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: "/",
                        }}
                    />
                ) : (
                    React.createElement(component, props)
                )
            }
        />
    );
}


export default App;
