import React from 'react';
import { makeStyles } from "@material-ui/styles";
import globalStyles from "../helpers/GlobalStyles";
import '../App.css';
import logo from '../svgs/darwin.svg';
import i1 from '../images/ios/1.jpeg';
import i2 from '../images/ios/2.jpeg';
import i3 from '../images/ios/3.jpeg';
import i4 from '../images/ios/4.jpeg';
import i5 from '../images/ios/5.jpeg';
import i6 from '../images/ios/6.jpeg';
import i7 from '../images/ios/7.jpeg';
import i8 from '../images/ios/8.jpeg';
import i9 from '../images/ios/9.jpeg';
import i10 from '../images/ios/10.jpeg';
import i11 from '../images/ios/11.jpeg';
import {Fab} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        background: "#000 !important",
        color: "#fff !important"
    },
    helpBk: {
        padding: "10px",
        background: "#000",
        "& img": {
            maxWidth: "100%"
        }
    },
    helpImg: {
        marginTop: "10px",
    },
    fabDiv: {
        position: 'fixed',
        bottom: 16,
        right: 16,
        maxWidth: "600px"
    },
    fabStyle: {
        fontFamily: 'IRANYekanXVF'
    }
}), {index: 1});

function Ios() {
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <div className="App" >
            <a className={globalClasses.logoDiv}>
                <img className={globalClasses.logo} src={logo} />
            </a>
            <p className={`${globalClasses.title} ${classes.title}`}>
                راهنمای نصب و راه اندازی نرم افزار AnyConnect برای آیفون
            </p>

            <div className={classes.helpBk}>
                <img src={i1} />
                <img className={classes.helpImg} src={i2} />
                <img className={classes.helpImg} src={i3} />
                <img className={classes.helpImg} src={i4} />
                <img className={classes.helpImg} src={i5} />
                <img className={classes.helpImg} src={i6} />
                <img className={classes.helpImg} src={i7} />
                <img className={classes.helpImg} src={i8} />
                <img className={classes.helpImg} src={i9} />
                <img className={classes.helpImg} src={i10} />
                <img className={classes.helpImg} src={i11} />
            </div>
            <div className={classes.fabDiv}>
                <Fab className={classes.fabStyle} aria-label='برگشت' color='primary' href="/">
                    {/*<EditIcon />*/}
                    برگشت
                </Fab>
            </div>
        </div>
    );
}

export default Ios;