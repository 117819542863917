import React from 'react';
import { makeStyles } from "@material-ui/styles";
import globalStyles from "../helpers/GlobalStyles";
import '../App.css';
import logo from '../svgs/darwin.svg';
import i1 from '../images/wany/1.png';
import i2 from '../images/wany/2.png';
import i3 from '../images/wany/3.png';
import i4 from '../images/wany/4.png';
import {Fab} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        background: "#000 !important",
        color: "#fff !important"
    },
    helpBk: {
        padding: "10px",
        background: "#000",
        "& img": {
            maxWidth: "100%"
        }
    },
    helpImg: {
        marginTop: "10px",
    },
    fabDiv: {
        position: 'fixed',
        bottom: 16,
        right: 16,
        maxWidth: "600px"
    },
    fabStyle: {
        fontFamily: 'IRANYekanXVF'
    },
    helpText: {
        background: "#000 !important",
        color: "#fff !important",
        margin: 0,
        paddingTop: 10
    }
}), {index: 1});

function WindowsOpen() {
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <div className="App" >
            <a className={globalClasses.logoDiv}>
                <img className={globalClasses.logo} src={logo} />
            </a>
            <p className={`${globalClasses.title} ${classes.title}`}>
                راهنمای نصب و راه اندازی نرم افزار Anyconnect برای ویندوز
            </p>
            <p className={classes.helpText}>
                ابتدا نرم افزار را دریافت کنید و سپس آن را نصب کنید.
            </p>
            <div className={classes.helpBk}>
                <img className={classes.helpImg} src={i1} />
                <p className={classes.helpText}>
                    بعد از دانلود و باز کردن برنامه
                    آدرس سرور  رو اینجا اضافه کنید
                    دقت کنید درصورت امکان http:// اول ادرس سرور نباشه
                </p>
                <img className={classes.helpImg} src={i2} />
                <p className={classes.helpText}>
                    در صورت دیدن این پیغام change setting رو بزنید
                </p>
                <img className={classes.helpImg} src={i3} />
                <p className={classes.helpText}>
                    این گزینه رو غیر فعال کنید
                    دوباره به سرور کانکت کنید
                    در مرحله بعدی یوزر و پسورد را وارد کنید
                </p>
                <img className={classes.helpImg} src={i4} />
                <p className={classes.helpText}>
                    نهایتا connect anyway رو بزنید و استفاده کنید
                </p>
            </div>
            <div className={classes.fabDiv}>
                <Fab className={classes.fabStyle} aria-label='برگشت' color='primary' href="/">
                    {/*<EditIcon />*/}
                    برگشت
                </Fab>
            </div>
        </div>
    );
}

export default WindowsOpen;