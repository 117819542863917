import { createStyles, makeStyles } from '@material-ui/core';

const GlobalStyles = makeStyles((theme) =>
    createStyles({
        title: {
            background: "#fff",
            margin: "30px 0 0 0",
            padding: "10px",
            borderTopRightRadius: "20px !important",
            borderTopLeftRadius: "20px !important",
        },
        logoDiv: {
            width: "40vmin",
            maxWidth: "200px",
            height: "auto",
            display: "block",
            margin: "auto",
            borderRadius: "50%",
            boxShadow: "1px 1px 4px 1px #77777744",
            border: "2px solid #88888855",
            background: "#fff"
        },
        logo: {
            padding: "20%"
        }
    })
    , {index: 1});


export default GlobalStyles;
