import React from 'react';
import { makeStyles } from "@material-ui/styles";
import globalStyles from "../helpers/GlobalStyles";
import '../App.css';
import logo from '../svgs/darwin.svg';
import i1 from '../images/aopen/1.jpeg';
import i2 from '../images/aopen/2.jpeg';
import i3 from '../images/aopen/3.jpeg';
import i4 from '../images/aopen/4.jpeg';
import i5 from '../images/aopen/5.jpeg';
import i6 from '../images/aopen/6.jpeg';
import i7 from '../images/aopen/7.jpeg';
import i8 from '../images/aopen/8.jpeg';
import i9 from '../images/aopen/9.jpeg';
import {Fab} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        background: "#000 !important",
        color: "#fff !important"
    },
    helpBk: {
        padding: "10px",
        background: "#000",
        "& img": {
            maxWidth: "100%"
        }
    },
    helpImg: {
        marginTop: "10px",
    },
    fabDiv: {
        position: 'fixed',
        bottom: 16,
        right: 16,
        maxWidth: "600px"
    },
    fabStyle: {
        fontFamily: 'IRANYekanXVF'
    },
    helpText: {
        background: "#000 !important",
        color: "#fff !important",
        margin: 0,
        paddingTop: 10
    }
}), {index: 1});

function AndroidOpen() {
    const classes = useStyles();
    const globalClasses = globalStyles();

    return (
        <div className="App" >
            <a className={globalClasses.logoDiv}>
                <img className={globalClasses.logo} src={logo} />
            </a>
            <p className={`${globalClasses.title} ${classes.title}`}>
                راهنمای نصب و راه اندازی نرم افزار OpenConnect برای آندروید
            </p>
            <p className={classes.helpText}>
                ابتدا نرم افزار را نصب کنید و سپس روی دکمه + لمس کنید
            </p>
            <div className={classes.helpBk}>
                <img src={i1} />
                <p className={classes.helpText}>
                    آدرس سرور را وارد کنید:
                </p>
                <img className={classes.helpImg} src={i2} />
                <p className={classes.helpText}>
                    دکمه عقب (back) را لمس کنید
                </p>
                <img className={classes.helpImg} src={i3} />
                <p className={classes.helpText}>
                    روی اسم پروفایل لمس کنید تا متصل شود
                </p>
                <img className={classes.helpImg} src={i4} />
                <img className={classes.helpImg} src={i5} />
                <img className={classes.helpImg} src={i6} />
                <p className={classes.helpText}>
                    یوزرنیم را وارد کنید:
                </p>
                <img className={classes.helpImg} src={i7} />
                <p className={classes.helpText}>
                    پسورد را وارد کندی:
                </p>
                <img className={classes.helpImg} src={i8} />
                <img className={classes.helpImg} src={i9} />
            </div>
            <div className={classes.fabDiv}>
                <Fab className={classes.fabStyle} aria-label='برگشت' color='primary' href="/">
                    {/*<EditIcon />*/}
                    برگشت
                </Fab>
            </div>
        </div>
    );
}

export default AndroidOpen;