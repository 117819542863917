import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { ExpandMore } from '@material-ui/icons';
import {Accordion, AccordionSummary, AccordionDetails, Button} from '@material-ui/core';
import globalStyles from "../helpers/GlobalStyles";
import '../App.css';
import logo from '../svgs/darwin.svg' ;


function Main() {
    const globalClasses = globalStyles();

    return (
        <div className="App" >
            <a className={globalClasses.logoDiv}>
                <img className={globalClasses.logo} src={logo} />
            </a>
            <p className={globalClasses.title}>
                آموزش همراه لینک ابزارها برای تمام سیستم عامل ها
            </p>
            <Accordion>

                <AccordionSummary  expandIcon={<ExpandMore />}>
                    آیفون (iOS)
                </AccordionSummary>

                <AccordionDetails>

                    <Button variant="text" href="https://apps.apple.com/us/app/cisco-secure-client/id1135064690" target="_blank">
                        دانلود برنامه AnyConnect
                    </Button>
                    <Button variant="text" href="/ios">
                        آموزش و راهنمای نصب AnyConnect
                    </Button>
                    <Button variant="text" href="/ios-change-server">
                       آموزش تغیر سرور در AnyConnect
                    </Button>

                    <Button variant="text" href="https://apps.apple.com/us/app/maodou-connect/id6476105995" target="_blank">
                        دانلود برنامه Maodou
                    </Button>
                    <Button variant="text" href="/ios-maodou">
                        آموزش و راهنمای نصب Maodou
                    </Button>

                </AccordionDetails>

            </Accordion>

            <Accordion>

                <AccordionSummary  expandIcon={<ExpandMore />}>
                    اندروید (Android)
                </AccordionSummary>
                <AccordionDetails>

                    <Button variant="text" href="files/DarwinVpn-1.0.7.apk" target="_blank">
                        دانلود برنامه اختصاصی داروین (پیشنهادی)
                    </Button>
                    <Button variant="text" href="files/openconnect-best-1.13.apk" target="_blank">
                        دانلود برنامه OpenConnect
                    </Button>
                    <Button variant="text" href="/android-openConnect">
                        آموزش و راهنمای نصب OpenConnect
                    </Button>

                    <Button variant="text" href="files/Cisco-Secure-Client-AnyConnect_5.0.02078.apk" target="_blank">
                        دانلود برنامه AnyConnect
                    </Button>
                    {/*<Button variant="text">*/}
                    {/*    آموزش و راهنمای نصب*/}
                    {/*</Button>*/}

                </AccordionDetails>

            </Accordion>

            <Accordion>

                <AccordionSummary  expandIcon={<ExpandMore />}>
                    ویندوز (Windows)
                </AccordionSummary>

                <AccordionDetails>
                    <Button variant="text" href="files/openconnect-gui-1.6.0-win64.exe" target="_blank">
                        دانلود برنامه OpenConnect برای ویندوز
                    </Button>
                    <Button variant="text" href="/windows-openConnect">
                        آموزش و راهنمای نصب OpenConnect
                    </Button>
                    <Button variant="text" href="files/anyconnect-win-4.10.00093-core-vpn-predeploy-k9.msi" target="_blank">
                        دانلود برنامه AnyConnect
                    </Button>
                    <Button variant="text" href="/windows-anyconnect">
                        آموزش و راهنمای نصب AnyConnect
                    </Button>
                </AccordionDetails>

            </Accordion>

            <Accordion>

                <AccordionSummary  expandIcon={<ExpandMore />}>
                    مک (MacOS)
                </AccordionSummary>

                <AccordionDetails>
                    <Button variant="text" href="files/OpenConnect-GUI.zip" target="_blank">
                        دانلود نرم افزار OpenConnect برای Mac
                    </Button>
                    {/*<Button variant="text" href="files/anyconnect-macos-4.10.00093-predeploy-k9.dmg" target="_blank">*/}
                    {/*    دانلود نرم افزار AnyConnect برای Mac*/}
                    {/*</Button>*/}
                    {/*<Button variant="text" href="files/anyconnect-macos-4.10.05095-core-vpn-webdeploy-k9.dmg" target="_blank">*/}
                    {/*    دانلود نرم افزار AnyConnect Core VPN برای Mac*/}
                    {/*</Button>*/}
                    {/*<Button variant="text" href="files/anyconnect-macos-4.9.00086-predeploy-k9.dmg" target="_blank">*/}
                    {/*    دانلود نرم افزار AnyConnect PredPloy 4.9 برای Mac*/}
                    {/*</Button>*/}
                </AccordionDetails>

            </Accordion>

        </div>
    );
}

export default Main;